export default {
  blogerName: 'PPA23',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@papodeaposta/videos',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c1d93cefc',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/cbc20409d',
      gameTitle: 'Royal High-Road',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c1bcf04e9',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c591bdb52',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/cd98d6252',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c82e5f6dd',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/ca3197696',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
  ],
  headerText: 'Regista-te com o código promocional <strong>PPA23</strong> e recebe 50 rodadas grátis em cada jogo disponível em baixo!',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'PPA23',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
}
